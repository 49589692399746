export function getRootNodes(arr) {
  return arr.filter((n) => !arr.some((t) => t.id === n.parentId));
}

export function getChildren(arr, nodeId) {
  return arr.filter((n) => n.parentId === nodeId);
}

export function getNodesSelectOptions(arr) {
  const rootNodes = getRootNodes(arr);
  let selectorArr = [...rootNodes];
  rootNodes.forEach((rn) => {
    selectorArr = [...selectorArr, ...getChildren(arr, rn.id)];
  });
  return selectorArr.map((n) => ({ value: n.id, label: n.name }));
}

export function getGlobalNodeSelectionOptions(arr) {
  const rootNodes = getRootNodes(arr);
  let optionNodes = [];
  rootNodes.forEach((rn) => {
    optionNodes = [...optionNodes, ...getNodeSelectOptionsFromTree(arr, rn)];
  });
  return optionNodes.sort((a, b) => (a.isLeaf && b.isLeaf ? 0 : a.isLeaf ? -1 : b.isLeaf ? -1 : 0));
}

function getNodeSelectOptionsFromTree(arr, rootNode) {
  const parentMap = {};
  const leafNodes = [];
  const subLeafNodeIds = new Set();
  arr.forEach((n) => {
    if (parentMap[n.parentId]) {
      parentMap[n.parentId].push(n);
    } else {
      parentMap[n.parentId] = [n];
    }
  });

  let maxDepth = 0;
  let tree = rootNode;
  let pointer = tree;

  function addToTree(node, depth) {
    const children = parentMap[node.id];
    if (depth > maxDepth) {
      maxDepth = depth;
    }
    if (children) {
      pointer = {
        depth,
        ...pointer,
        children,
      };
      // pointer.depth = depth;
      // pointer.children = children;
      children.forEach((cn) => {
        pointer = cn;
        addToTree(cn, depth + 1);
      });
    } else {
      node = {
        ...node,
        depth,
      };
      // node.depth = depth;
      leafNodes.push({ ...node });
    }
  }

  addToTree(rootNode, 0);
  const nodeSelectorNodes = leafNodes
    .filter((ln) => ln.depth === maxDepth)
    .map((ln) => ({ ...ln, isLeaf: true }));
  nodeSelectorNodes.forEach((ln) => {
    subLeafNodeIds.add(ln.parentId);
  });

  arr.forEach((n) => {
    if ([...subLeafNodeIds].includes(n.id)) {
      nodeSelectorNodes.push(n);
    }
  });
  return nodeSelectorNodes.map((n) => ({
    id: n.id,
    code: n.code,
    name: n.name,
    parentId: n.parentId,
    type: n.type,
    isLeaf: Boolean(n.isLeaf),
    locations: n.locations,
  }));
}
