import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { ga, useGDispatch, useGState } from 'state/store';
import { useAPI } from 'utils/customHooks';
import { getClientName } from 'utils/utils';

const useStyles = makeStyles((theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: 0,
  },
  padding: {
    width: '100%',
    height: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  loader: {
    width: '100%',
  },
  invalid: {
    color: red[500],
  },
}));

export default function Login() {
  return clientSSO(getClientName());
}

function clientSSO(clientName) {
  switch (clientName) {
    case 'dabur':
      // case 'localhost':
      return <DaburSSO />;
    case 'marico':
      return <MaricoSSO />;
    default:
      return <DefaultLogin />;
  }
}

function MaricoSSO() {
  const classes = useStyles();
  const { MARICO_SSO_URL } = useGState((s) => s[ga.PANEL_CONFIG]);

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <div className={classes.paperContainer}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.href = MARICO_SSO_URL;
            }}
            className={classes.submit}
          >
            Sign In
          </Button>
        </div>
      </Paper>
    </main>
  );
}

function DaburSSO() {
  const classes = useStyles();
  const { DABUR_SSO_URL } = useGState((s) => s[ga.PANEL_CONFIG]);

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        <div className={classes.paperContainer}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.href = DABUR_SSO_URL;
            }}
            className={classes.submit}
          >
            Sign In
          </Button>
        </div>
      </Paper>
    </main>
  );
}

function DefaultLogin() {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState(false);
  const gDispatch = useGDispatch();
  const [fetch, , authLoading] = useAPI();

  const onLoginClick = () =>
    fetch(
      {
        method: 'POST',
        url: '/core/password_login/cookie',
        headers: {
          Authorization: `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`,
        },
      },
      (response) => {
        gDispatch([ga.USER, response]);
      },
      () => setLoginError(true)
    );

  const enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      onLoginClick();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        {authLoading ? (
          <LinearProgress className={classes.loader} />
        ) : (
          <div style={{ height: '4px' }} />
        )}
        <div className={classes.paperContainer}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <div className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <Input
                id="userName"
                name="userName"
                value={username}
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value.toUpperCase())}
                autoComplete="userName"
                onKeyPress={enterPressed}
                autoFocus
                // endAdornment={
                //   <InputAdornment position="end">
                //     <IconButton
                //       aria-label="user name"
                //       onMouseDown={handleMouseDownPassword}
                //     >
                //       <AccountCircle />
                //     </IconButton>
                //   </InputAdornment>
                // }
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <Input
                name="password"
                value={password}
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                onKeyPress={enterPressed}
                autoComplete="current-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {loginError ? (
              <Typography className={classes.invalid}>Invalid username/password</Typography>
            ) : (
              <div style={{ height: 20 }} />
            )}
            <Button
              // type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={onLoginClick}
              className={classes.submit}
            >
              Login
            </Button>
          </div>
        </div>
      </Paper>
    </main>
  );
}
