import store from 'store';
import { navigate } from 'gatsby';

export const set = (key, value) => store.set(key, value);
export const get = (key) => store.get(key);
export const remove = (key) => store.remove(key);

export const getAuthToken = () => {
  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxMTI0MzU5MDk4NjY0MTEzMjg5MTUiLCJkb21haW4iOiJzdGFja2JveC54eXoiLCJpYXQiOjE1NzgzMjA1MzksImV4cCI6MTYwOTg1NjUzOX0.op0Nkb4R9ijMfw_nirWfl3PAlNUSIdJo8Yp0wk9kX6Q';

  // return store.get('x-access-token');
};

export const setAuthToken = (token) => {
  return store.set('x-access-token', token);
};

export const getUser = () => {
  return store.get('user');
};

export const setUser = (user) => {
  return store.set('user', user);
};

export const clearAuthToken = () => {
  return store.remove('x-access-token');
};

export const clearUserData = () => {
  store.remove('x-access-token');
  store.remove('user');
  // navigate('/login');
};

export const logout = () => {
  store.remove('x-access-token');
  store.remove('user');
  navigate('/login');
};

export default store;
