/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import App from './src/App';

export const wrapRootElement = App;

export const onInitialClientRender = () => {
  // eslint-disable-next-line no-console
  console.log('Removing service worker');
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
};

// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//     `This application has been updated. ` + `Reload to display the latest version?`
//   );
//   if (answer === true) {
//     window.location.reload();
//   }
// };
