import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { globalHistory } from '@reach/router';
import 'chartjs-plugin-datalabels';
import InitialSetup from 'InitialSetup';
import Login from 'Login';
import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { ReactQueryConfigProvider } from 'react-query';
import { createStore, ga, StoreProvider, useGDispatch, useGState } from 'state/store';
import {
  DateParam,
  NumberParam,
  QueryParamProvider,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import { useFetch, useSnackbar } from 'utils/customHooks';
import { initialSetup as apiInitialSetup } from 'utils/fetch';
import { clearUserData } from 'utils/persistentStore';
import SnackbarProvider from './components/Snackbar';
import './styles/app.css';

require('typeface-roboto');

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#07162a',
      dark: '#07162a',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  typography: { useNextVariants: true },
});

const queryConfig = {
  suspense: true,
  retry: false,
  refetchAllOnWindowFocus: false,
  cacheTime: 60 * 1000,
};

export default ({ element }) => {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Panel</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        {/* <base href="/public/" /> */}
      </Helmet>
      <MuiThemeProvider theme={theme}>
        <QueryParamProvider reachHistory={globalHistory}>
          <ReactQueryConfigProvider config={queryConfig}>
            <App>{element}</App>
          </ReactQueryConfigProvider>
        </QueryParamProvider>
      </MuiThemeProvider>
    </>
  );
};

function App({ children }) {
  const [query, setQuery] = useQueryParams({
    branchId: NumberParam,
    nodeId: StringParam,
    date: DateParam,
  });
  const isClient = typeof window === 'object';
  const [user, , , , isSettled] = useFetch('/core/account');

  const store = useMemo(() => {
    return createStore({ query, setQuery: isClient ? setQuery : () => {} });
    //eslint-disable-next-line
  }, [setQuery]);
  return (
    <StoreProvider store={store}>
      <SnackbarProvider>
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <AppApiInitialSetup user={user} />
          {isSettled && (
            <AuthEnabledApp query={query} user={user}>
              {children}
            </AuthEnabledApp>
          )}
        </LocalizationProvider>
      </SnackbarProvider>
    </StoreProvider>
  );
}

function AppApiInitialSetup() {
  const gDispatch = useGDispatch();
  const [notif] = useSnackbar();
  const [config] = useFetch('/panelConfig');
  useEffect(() => {
    if (!config) return;
    gDispatch([ga.PANEL_CONFIG, config]);
  }, [config, gDispatch]);
  useEffect(() => {
    const onAuthFailure = () => {
      clearUserData();
      gDispatch([ga.USER, null]);
    };
    apiInitialSetup(onAuthFailure, notif);
  }, [gDispatch, notif]);
  return <></>;
}

function AuthEnabledApp({ query, children, user }) {
  const storeUser = useGState((s) => s.user);
  const permissions = useGState((s) => s.permission);
  const isClient = typeof window === 'object';

  return isClient && (!(user || storeUser) || storeUser === null) ? (
    ['/ssoredirect', '/ssoredirect/'].includes(window?.location?.pathname?.toLowerCase().trim()) ? (
      children
    ) : (
      <Login />
    )
  ) : (
    <>
      {permissions && children}
      <CssBaseline />
      <InitialSetup query={query} />
    </>
  );
}
