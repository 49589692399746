import axios from 'axios';

const instance = axios.create({
  baseURL: '/api',
  // baseURL: process.env.GATSBY_API,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 300000,
});

export const initialSetup = (onAuthFailure, notif) => {
  instance.interceptors.response.use(
    (response) => response,
    function (error) {
      if (error.response?.status === 401) {
        console.log('UN-AUTHENTICATED'); // eslint-disable-line
        onAuthFailure();
      } else {
        if (error.response?.data?.message) {
          notif(error.response.data.message, { variant: 'error' });
        }
        if (error.request?.responseType == 'blob') {
          error.response?.data
            ?.text()
            .then((e) => notif(JSON.parse(e).message, { variant: 'error' }));
        }
      }
      return Promise.reject(error.response);
    }
  );
};
const fetch = async (details) => {
  return instance(details).then((r) => (details.wholeResponse ? r : r.data));
};

export default fetch;
