import produce from 'immer';
import { useCallback } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { createStore as createStoreRedux } from 'redux';
import { getUser, setUser } from 'utils/persistentStore';
import { formatDate, getWorkingDate } from 'utils/utils';

export { Provider as StoreProvider, useSelector as useGState };

export const ga = {
  REASONS: 'REASONS',
  FAILURE_REASONS: 'failReasons',
  PARTIAL_FAILURE_REASONS: 'partialFailReasons',
  SKIP_POD_OTP_REASONS: 'skipPodOTPReasons',
  DATE: 'DATE',
  USER: 'user',
  BRANCHES: 'BRANCHES',
  BRANCH: 'branch',
  CLIENT_PREFERENCE: 'clientPreference',
  METRICS: 'METRICS',
  LAST_UPDATED: 'lastUpdated',
  PANEL_CONFIG: 'panelConfig',
  NODES: 'nodes',
  NODE: 'node',
  PERMISSIONS: 'permission',
};

let setQuery;

const getInitialState = ({ query, setQuery: ssetQuery }) => {
  const urlState = {
    date: query.date ? formatDate(query.date) : formatDate(getWorkingDate()),
  };
  setQuery = ssetQuery;

  return {
    ...urlState,
    user: getUser() || undefined,
    lastUpdated: '',
    branch: null,
    branches: [],
    nodes: [],
    node: null,
    permission: undefined,
    mapView: false,
    metrics: {
      avgDuration: null,
      avgDistance: null,
    },
    panelConfig: {},
    //TODO structure properly maybe inside settings or preferences
    clientPreference: {},
    settings: {
      colorCode: {
        weight_util: {
          boundaries: [35, 45],
        },
        volume_util: {
          boundaries: [35, 45],
        },
        avg_drops: {
          boundaries: [35, 45],
        },
        retry: {
          boundaries: [2, 3],
          colors: ['$green', '$yellow', '$red'],
        },
        off_route: {
          boundaries: [2, 3],
          colors: ['$green', '$yellow', '$red'],
        },
        avg_planned_time: {
          boundaries: [5, 6],
        },
        in_market_time: {
          boundaries: [4.5, 5],
        },
        in_store_time: {
          boundaries: [3, 3.5],
        },
        sales_compliance: {
          boundaries: [50, 70],
        },
        delivery_compliance: {
          boundaries: [100],
          colors: ['$green ', '$grey'],
        },
        lead_time: {
          boundaries: [1.05, 1.2],
          colors: ['$green', '$yellow', '$red'],
        },
      },
    },
    failureReasons: [],
  };
};

const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ga.REASONS:
        draft[ga.FAILURE_REASONS] = action.data?.failReasons;
        draft[ga.PARTIAL_FAILURE_REASONS] = action.data?.partialFailReasons;
        draft[ga.SKIP_POD_OTP_REASONS] = action.data?.skipPodOTPReasons;
        break;
      case ga.DATE:
        draft.date = formatDate(action.data);
        setQuery({ date: action.data });
        break;
      case ga.BRANCHES:
        draft.branches = action.data;
        break;
      case ga.BRANCH:
        draft[action.type] = action.data;
        setQuery({ branchId: action.data.id });
        break;
      case ga.NODE:
        draft[action.type] = action.data;
        setQuery({ nodeId: action.data.id });
        break;
      case ga.USER:
        draft[action.type] = action.data;
        setUser(action.data);
        break;
      case ga.PANEL_CONFIG:
        draft[action.type] = action.data;
        break;
      case ga.LAST_UPDATED:
      case ga.CLIENT_PREFERENCE:
      case ga.NODES:
      case ga.PERMISSIONS:
        draft[action.type] = action.data;
        break;
      case ga.METRICS:
        draft.metrics = { ...state.metrics, ...action.data };
        break;
    }
  });

// preloadedState will be passed in by the plugin
export const createStore = (globalQueryParams) =>
  createStoreRedux(reducer, getInitialState(globalQueryParams));

export function useGDispatch() {
  const dispatch = useDispatch();
  const gDispatch = useCallback(
    (action) => {
      if (!action) return;
      if (Array.isArray(action)) {
        dispatch({
          type: action[0],
          data: action[1],
        });
      } else {
        dispatch(action);
      }
    },
    [dispatch]
  );
  return gDispatch;
}
